<template>
	<div>
		<v-tabs v-model="tabModel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('visit.tabs.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#ship'" v-if="!isCreatingElement && hasShipFunctionality">{{ $t('visit.tabs.ship') }}</v-tab>
			<v-tab :key="2" :href="'#stop'" v-if="!isCreatingElement && !isModalDialog && hasStopFunctionality">{{ $t('visit.tabs.stop') }}</v-tab>
			<v-tab :key="3" :href="'#operation'" v-if="!isCreatingElement && !isModalDialog && hasOperationFunctionality">{{
				$t('visit.tabs.operation')
			}}</v-tab>
			<v-tab :key="4" :href="'#movement'" v-if="!isCreatingElement && !isModalDialog && hasMovementFunctionality">{{
				$t('visit.tabs.movement')
			}}</v-tab>
			<v-tab :key="5" :href="'#pilotage'" v-if="!isCreatingElement && !isModalDialog && hasPilotageFunctionality">{{
				$t('visit.tabs.pilotage')
			}}</v-tab>
			<v-tab :key="6" :href="'#mooring'" v-if="!isCreatingElement && !isModalDialog && hasMooringFunctionality">{{
				$t('visit.tabs.mooring')
			}}</v-tab>
			<v-tab :key="7" :href="'#towage'" v-if="!isCreatingElement && !isModalDialog && hasTowageFunctionality">{{
				$t('visit.tabs.towage')
			}}</v-tab>
			<v-tab :key="8" :href="'#events'" v-if="!isCreatingElement && !isModalDialog">{{ $t('visit.tabs.events') }}</v-tab>
			<v-tab :key="9" :href="'#eventsais'" v-if="!isCreatingElement && !isModalDialog && hasLogAisEventsFunctionality">{{
				$t('visit.tabs.eventsais')
			}}</v-tab>
			<v-tab :key="10" :href="'#visitchat'" v-if="!isCreatingElement && !isModalDialog">{{ $t('visit.tabs.chat') }}</v-tab>
			<v-tab :key="11" :href="'#consignmentchange'" v-if="!isCreatingElement && !isModalDialog && hasConsigChangeFunctionality">{{
				$t('visit.tabs.consignmentchange')
			}}</v-tab>
			<v-tab :key="12" :href="'#documents'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabModel">
			<v-tab-item :key="0" lazy :value="'maintab'">
				<v-layout wrap class="pui-form-layout">
					<v-flex xs12 md7>
						<pui-field-set :title="$t('visit.fieldset.info')">
							<v-layout wrap>
								<v-flex xs12 md6>
									<pui-select
										:id="`portname-${modelName}`"
										:attach="`portname-${modelName}`"
										:label="this.$t('visit.portname')"
										toplabel
										clearable
										required
										:disabled="!isCreatingElement"
										v-model="model"
										modelName="port"
										:itemsToSelect="itemsToSelectPort"
										:modelFormMapping="{ id: 'portid' }"
										itemValue="id"
										itemText="portname"
										reactive
									></pui-select>
								</v-flex>
								<v-flex xs3>
									<v-layout>
										<v-flex xs12 md6>
											<pui-number-field
												:id="`visityear-${modelName}`"
												:label="$t('visit.visityear')"
												v-model="model.visityear"
												required
												maxlength="4"
												decimals="0"
												min="0"
												max="9999"
												toplabel
												:disabled="!isCreatingElement"
											></pui-number-field>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="!isCreatingElement">
								<v-flex xs12 md4>
									<pui-text-field
										:id="`portcallnumber-${modelName}`"
										:label="$t('visit.portcallnumber')"
										v-model="model.portcallnumber"
										maxlength="30"
										toplabel
										disabled
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md4>
									<pui-select
										:id="`status-${modelName}`"
										:attach="`status-${modelName}`"
										:label="$t('visit.statusname')"
										toplabel
										clearable
										required
										disabled
										v-model="model"
										modelName="visitstatuspa"
										:itemsToSelect="itemsToSelectStatus"
										:modelFormMapping="{ visitstatuscode: 'status' }"
										itemValue="visitstatuscode"
										itemText="visitstatusname"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>

							<v-layout v-if="model.status === 'AN'">
								<v-flex xs12>
									<pui-text-area
										:id="`commentsstatus-${modelName}`"
										:label="$t('visit.commentsstatus')"
										v-model="model.commentsstatus"
										toplabel
										disabled
										maxlength="300"
									></pui-text-area>
								</v-flex>
							</v-layout>
							<v-layout>
								<v-flex xs12>
									<v-layout wrap>
										<v-flex xs12 md12 lg6>
											<pui-select
												:id="`consigneecompany-${modelName}`"
												:attach="`consigneecompany-${modelName}`"
												:label="$t('visit.consigneecompany')"
												toplabel
												clearable
												required
												:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
												v-model="model"
												modelName="company"
												:itemsToSelect="itemsToSelectConsigneeCompany"
												:modelFormMapping="{ id: 'consigneeid' }"
												:itemValue="['id']"
												:itemText="(item) => `${item.name}`"
												:order="{ name: 'asc' }"
												showOnlyActives
												reactive
												:fixedFilter="filterByConsigneeCompanyTypeAndPort"
											></pui-select>
										</v-flex>
										<v-flex xs12 md12 lg6>
											<pui-select
												:id="`ship-${modelName}`"
												:attach="`ship-${modelName}`"
												:label="$t('visit.vesselname')"
												:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
												toplabel
												clearable
												required
												v-model="model"
												modelName="ship"
												:itemsToSelect="itemsToSelectShip"
												:modelFormMapping="{ id: 'shipid' }"
												:itemValue="['id']"
												:itemText="(item) => `${item.imo} - ${item.name}`"
												:order="{ name: 'asc' }"
												:fixedFilter="filterShipVersion"
												showOnlyActives
												reactive
											></pui-select>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout>
								<v-flex xs12>
									<v-layout wrap>
										<v-flex xs12 md6 lg6>
											<pui-select
												:id="`fromportcode-${modelName}`"
												:attach="`fromportcode-${modelName}`"
												:label="$t('visit.fromportname')"
												:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
												toplabel
												clearable
												required
												v-model="model"
												modelName="worldport"
												:modelFormMapping="{ code: 'fromportcode' }"
												:itemsToSelect="itemsToSelectFromPortCode"
												:itemValue="['code']"
												:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
												reactive
											></pui-select>
										</v-flex>
										<v-flex xs12 md6 lg6>
											<pui-select
												:id="`toportcode-${modelName}`"
												:attach="`toportcode-${modelName}`"
												:label="$t('visit.toportname')"
												toplabel
												clearable
												required
												:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
												v-model="model"
												modelName="worldport"
												:modelFormMapping="{ code: 'toportcode' }"
												:itemsToSelect="itemsToSelectToPortCode"
												:itemValue="['code']"
												:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
												reactive
											></pui-select>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="!isCreatingElement">
								<v-flex xs12>
									<v-layout>
										<v-flex xs12 md6>
											<pui-select
												:id="`maritimeservice-${modelName}`"
												:attach="`maritimeservice-${modelName}`"
												:label="$t('visit.maritimeservice')"
												toplabel
												clearable
												:disabled="disableForm || model.status !== firstStatusByPA"
												v-model="model"
												modelName="maritimeservice"
												:modelFormMapping="{ maritimeserviceid: 'maritimeserviceid' }"
												:itemsToSelect="itemsToSelectMaritimeService"
												itemValue="maritimeserviceid"
												:itemText="(item) => `${item.maritimeservicecode} - ${item.name}`"
												showOnlyActives
												reactive
											></pui-select>
										</v-flex>
										<v-flex xs12 md6>
											<pui-select
												:id="`maritimeserviceexit-${modelName}`"
												:attach="`maritimeserviceexit-${modelName}`"
												:label="$t('visit.maritimeserviceexit')"
												toplabel
												clearable
												:disabled="disableForm || model.status !== firstStatusByPA"
												v-model="model"
												modelName="maritimeservice"
												:modelFormMapping="{ maritimeserviceid: 'maritimeserviceexitid' }"
												:itemsToSelect="itemsToSelectMaritimeServiceExit"
												itemValue="maritimeserviceid"
												:itemText="(item) => `${item.maritimeservicecode} - ${item.name}`"
												showOnlyActives
												reactive
											></pui-select>
										</v-flex>
									</v-layout>
								</v-flex>
								<v-flex xs12 md6>
									<pui-select
										:id="`traffictypecode-${modelName}`"
										:attach="`traffictypecode-${modelName}`"
										:label="this.$t('visit.traffictypecode')"
										toplabel
										clearable
										:disabled="disableForm || model.status !== firstStatusByPA"
										v-model="model"
										modelName="traffictype"
										:itemsToSelect="itemsToSelectTrafficType"
										:modelFormMapping="{ traffictypecode: 'traffictypecode' }"
										itemValue="traffictypecode"
										itemText="traffictypename"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="!isCreatingElement">
								<v-flex xs12 md6>
									<v-layout wrap>
										<v-flex xs12>
											<pui-date-field
												:id="`captaincyautdate-${modelName}`"
												:label="$t('visit.captaincyautdate')"
												v-model="model.captaincyautdate"
												toplabel
												time
												:disabled="disableForm || model.status !== firstStatusByPA"
											></pui-date-field>
										</v-flex>
										<v-flex xs12>
											<pui-select
												:id="`captaincystatus-${modelName}`"
												:attach="`captaincystatus-${modelName}`"
												:label="$t('visit.captaincystatus')"
												toplabel
												clearable
												:disabled="disableForm || model.status !== firstStatusByPA"
												v-model="model"
												modelName="captaincystatus"
												:itemsToSelect="itemsToSelectCaptaincyStatus"
												:modelFormMapping="{ captaincystatusid: 'captaincystatus' }"
												itemValue="captaincystatusid"
												itemText="description"
												reactive
											></pui-select>
										</v-flex>
									</v-layout>
								</v-flex>
								<v-flex xs12 md6>
									<v-layout wrap>
										<v-flex xs12>
											<pui-text-area
												:id="`captaincycomment-${modelName}`"
												:label="$t('visit.captaincycomment')"
												v-model="model.captaincycomment"
												toplabel
												:disabled="disableForm || model.status !== firstStatusByPA"
												maxlength="300"
											></pui-text-area>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="!isCreatingElement">
								<v-flex xs12 md6>
									<pui-text-area
										:id="`commentspilotage-${modelName}`"
										:label="$t('visit.commentspilotage')"
										v-model="model.commentspilotage"
										toplabel
										:disabled="!editCommentsPilotage"
										maxlength="300"
									></pui-text-area>
								</v-flex>
								<v-flex xs12 md6>
									<pui-text-area
										:id="`comments-${modelName}`"
										:label="$t('visit.comments')"
										v-model="model.comments"
										toplabel
										:disabled="disableForm || model.status !== firstStatusByPA"
										maxlength="300"
									></pui-text-area>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<pui-field-set :title="$t('visit.fieldset.isps')" v-if="!isCreatingElement && ispsVisit">
							<v-layout wrap>
								<v-flex xs12>
									<VisitispsForm
										:formDisabled="formDisabled"
										:modelLoaded="modelLoaded"
										:isCreatingElement="isCreatingElement"
										:modelName="modelName"
										:model="model"
									></VisitispsForm>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12 md5>
						<pui-field-set :title="$t('visit.fieldset.dates')">
							<v-layout wrap>
								<v-flex xs12>
									<v-layout wrap>
										<v-flex xs12 sm6 md12 lg6>
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`requestdate-${modelName}`"
														:label="$t('visit.requestdate')"
														v-model="model.requestdate"
														toplabel
														required
														disabled
														time
													></pui-date-field>
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 sm6 md12 lg6 v-if="!isCreatingElement">
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`authdate-${modelName}`"
														:label="$t('visit.authdate')"
														v-model="model.authdate"
														toplabel
														disabled
														time
													></pui-date-field>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12>
									<v-layout wrap>
										<v-flex xs12 sm6 md12 lg6>
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`eta-${modelName}`"
														:label="$t('visit.eta')"
														v-model="model.eta"
														toplabel
														required
														:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
														time
														:max="model.etd"
													></pui-date-field>
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 sm6 md12 lg6>
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`etd-${modelName}`"
														:label="$t('visit.etd')"
														v-model="model.etd"
														toplabel
														required
														:disabled="disableForm || (!isCreatingElement && model.status !== firstStatusByPA)"
														time
														:min="model.eta"
													></pui-date-field>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
							<v-layout wrap v-if="!isCreatingElement">
								<v-flex xs12>
									<v-layout wrap>
										<v-flex xs12 sm6 md12 lg6>
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`ata-${modelName}`"
														:label="$t('visit.ata')"
														v-model="model.ata"
														toplabel
														disabled
														time
														:max="model.atd"
													></pui-date-field>
													<pui-checkbox
														:id="`isautoata-${modelName}`"
														:label="$t('visit.isautoata')"
														v-model="model.isautoata"
														disabled
													></pui-checkbox>
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 sm6 md12 lg6>
											<v-layout wrap>
												<v-flex xs12>
													<pui-date-field
														:id="`atd-${modelName}`"
														:label="$t('visit.atd')"
														v-model="model.atd"
														toplabel
														disabled
														time
														:min="model.ata"
													></pui-date-field>
													<pui-checkbox
														:id="`isautoatd-${modelName}`"
														:label="$t('visit.isautoatd')"
														v-model="model.isautoatd"
														disabled
													></pui-checkbox>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<pui-field-set :title="$t('visit.fieldset.moreinfo')" v-if="!isCreatingElement">
							<v-layout wrap>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`authcabotage-${modelName}`"
										:label="$t('visit.authcabotage')"
										v-model="model.authcabotage"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`international-${modelName}`"
										:label="$t('visit.international')"
										v-model="model.international"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`manual-${modelName}`"
										:label="$t('visit.manual')"
										v-model="model.manual"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`cession-${modelName}`"
										:label="$t('visit.cession')"
										v-model="model.cession"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`holidayarrival-${modelName}`"
										:label="$t('visit.holidayarrival')"
										v-model="model.holidayarrival"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center v-if="seeDoubleTideFunc">
									<pui-checkbox
										:id="`doubletide-${modelName}`"
										:label="$t('visit.doubletide')"
										v-model="model.doubletide"
										:disabled="!hasDoubleTideFunc"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md6 align-self-center>
									<pui-checkbox
										:id="`highfrequency-${modelName}`"
										:label="$t('visit.highfrequency')"
										v-model="model.highfrequency"
										:disabled="disableForm || model.status !== firstStatusByPA"
									></pui-checkbox>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6 md12 lg6>
									<v-layout wrap>
										<pui-field-set :title="$t('visit.fieldset.arrival')">
											<v-layout wrap>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`arrivalpassengersnumber-${modelName}`"
														:label="$t('visit.passengersnumber')"
														v-model="model.arrivalpassengersnumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`arrivalstowawaynumber-${modelName}`"
														:label="$t('visit.stowawaynumber')"
														v-model="model.arrivalstowawaynumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`arrivalcrewnumber-${modelName}`"
														:label="$t('visit.crewnumber')"
														v-model="model.arrivalcrewnumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<v-flex xs12 md12>
													<pui-text-field
														:id="`captainarrivalname-${modelName}`"
														:label="$t('visit.captainname')"
														v-model="model.captainarrivalname"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-text-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<v-flex xs12 md12>
													<pui-select
														:id="`captainarrivalcountry-${modelName}`"
														:attach="`captainarrivalcountry-${modelName}`"
														:label="this.$t('visit.captaincountry')"
														toplabel
														clearable
														:disabled="disableForm || model.status !== firstStatusByPA"
														v-model="model"
														modelName="country"
														:itemsToSelect="itemsToSelectCountryArrival"
														:modelFormMapping="{ code: 'captainarrivalcountry' }"
														itemValue="code"
														itemText="name"
														reactive
													></pui-select>
												</v-flex>
											</v-layout>
										</pui-field-set>
									</v-layout>
								</v-flex>
								<v-flex xs12 sm6 md12 lg6>
									<v-layout wrap>
										<pui-field-set :title="$t('visit.fieldset.exit')">
											<v-layout wrap>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`exitpassengersnumber-${modelName}`"
														:label="$t('visit.passengersnumber')"
														v-model="model.exitpassengersnumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`exitstowawaynumber-${modelName}`"
														:label="$t('visit.stowawaynumber')"
														v-model="model.exitstowawaynumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
												<v-flex xs12 md4>
													<pui-number-field
														:id="`exitcrewnumber-${modelName}`"
														:label="$t('visit.crewnumber')"
														v-model="model.exitcrewnumber"
														decimals="0"
														min="0"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-number-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<v-flex xs12 md12>
													<pui-text-field
														:id="`captainexitname-${modelName}`"
														:label="$t('visit.captainname')"
														v-model="model.captainexitname"
														toplabel
														:disabled="disableForm || model.status !== firstStatusByPA"
													></pui-text-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<v-flex xs12 md12>
													<pui-select
														:id="`captainexitcountry-${modelName}`"
														:attach="`captainexitcountry-${modelName}`"
														:label="this.$t('visit.captaincountry')"
														toplabel
														clearable
														:disabled="disableForm || model.status !== firstStatusByPA"
														v-model="model"
														modelName="country"
														:itemsToSelect="itemsToSelectCountryExit"
														:modelFormMapping="{ code: 'captainexitcountry' }"
														itemValue="code"
														itemText="name"
														reactive
													></pui-select>
												</v-flex>
											</v-layout>
										</pui-field-set>
									</v-layout>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<pui-field-set :title="$t('visit.fieldset.mmpp')" v-if="!isCreatingElement && mmppVisit">
							<v-layout wrap>
								<v-flex xs12>
									<VisitmmppForm
										:formDisabled="formDisabled"
										:modelLoaded="modelLoaded"
										:isCreatingElement="isCreatingElement"
										:modelName="modelName"
										:model="model"
									></VisitmmppForm>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<pui-field-set :title="$t('visit.fieldset.solas')" v-if="!isCreatingElement && solasVisit">
							<v-layout wrap>
								<v-flex xs12>
									<VisitsolasForm
										:formDisabled="formDisabled"
										:modelLoaded="modelLoaded"
										:isCreatingElement="isCreatingElement"
										:modelName="modelName"
										:model="model"
									></VisitsolasForm>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<custom-fields-generate-form
						:formDisabled="formDisabled"
						:isCreatingElement="isCreatingElement"
						:parentObject="model"
						:parentModelName="modelName"
						:parentPk="pk"
					></custom-fields-generate-form>
				</v-layout>
			</v-tab-item>
			<v-tab-item :key="1" :value="`ship`" lazy v-if="!isCreatingElement && hasShipFunctionality">
				<v-flex xs12>
					<ShipFormMainTab
						:modelName="modelNameShip"
						:parentObject="modelShip"
						formDisabled
						:isCreatingElement="isCreatingElement"
					></ShipFormMainTab>
				</v-flex>
			</v-tab-item>
			<v-tab-item :key="2" :value="`stop`" lazy v-if="!isCreatingElement && !isModalDialog && hasStopFunctionality">
				<pui-master-detail
					componentName="stopgrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:formDisabled="true"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="3" :value="`operation`" lazy v-if="!isCreatingElement && !isModalDialog && hasOperationFunctionality">
				<pui-master-detail
					componentName="operationgrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:formDisabled="true"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="4" :value="`movement`" lazy v-if="!isCreatingElement && !isModalDialog && hasMovementFunctionality">
				<pui-master-detail
					componentName="movementgrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:formDisabled="true"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="5" :value="`pilotage`" lazy v-if="!isCreatingElement && !isModalDialog && hasPilotageFunctionality">
				<pui-master-detail
					componentName="pilotagegrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="6" :value="`mooring`" lazy v-if="!isCreatingElement && !isModalDialog && hasMooringFunctionality">
				<pui-master-detail
					componentName="mooring-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="7" :value="`towage`" lazy v-if="!isCreatingElement && !isModalDialog && hasTowageFunctionality">
				<pui-master-detail
					componentName="towage-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail>
			</v-tab-item>
			<v-tab-item :key="8" :value="'events'" lazy v-if="!isCreatingElement && !isModalDialog">
				<div class="d-flex justify-center">
					<events-template :visit="model"></events-template>
				</div>
			</v-tab-item>
			<v-tab-item :key="9" :value="'eventsais'" lazy v-if="!isCreatingElement && !isModalDialog && hasLogAisEventsFunctionality">
				<pui-master-detail
					componentName="logaiseventextendedgrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visitid' }"
					:formDisabled="true"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail
			></v-tab-item>
			<v-tab-item :key="10" :value="'visitchat'" lazy v-if="!isCreatingElement && !isModalDialog">
				<operations-chat :visit="visitChat"></operations-chat>
			</v-tab-item>
			<v-tab-item :key="11" :value="'consignmentchange'" lazy v-if="!isCreatingElement && !isModalDialog && hasConsigChangeFunctionality">
				<pui-master-detail
					componentName="consignmentchangegrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'visit_id' }"
					:formDisabled="true"
					:modalDialog="isModalDialog"
					:parentModel="model"
				></pui-master-detail
			></v-tab-item>
			<v-tab-item :key="12" :value="`documents`" v-if="!isCreatingElement && showDocuments">
				<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import ShipFormMainTab from '@/components/ship/ShipFormMainTab';
import operationsChat from '@/components/chat/OperationsChat';
import StopGrid from '@/components/stop/StopGrid';
import EventsTemplate from './EventsTemplate';
import VisitmmppForm from './visitmmpp/VisitmmppForm';
import VisitsolasForm from './visitsolas/VisitsolasForm';
import VisitispsForm from './visitisps/VisitispsForm';

export default {
	name: 'visittemplateform',
	components: {
		ShipFormMainTab,
		operationsChat,
		StopGrid,
		EventsTemplate,
		VisitmmppForm,
		VisitsolasForm,
		VisitispsForm
	},
	data() {
		return {
			tabModel: this.value,
			initialModel: {},
			modelNameShip: 'ship',
			modelShip: {},
			visitChat: {},
			mmppVisit: false,
			solasVisit: false,
			ispsVisit: false,
			now: new Date(),
			consigneeCompanyType: 5,
			ownlistships: false,
			firstStatusByPA: '',
			pilotageCompanyType: 6,
			APCompanyType: 7,
			disableForm: true
		};
	},
	props: {
		value: {
			type: [Number, String],
			required: false
		},
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		},
		modelName: {
			type: String,
			required: true
		},
		isModalDialog: {
			type: Boolean,
			default: false
		},
		pk: {
			type: [Object, String],
			required: true
		},
		showDocuments: {
			type: Boolean,
			required: true
		}
	},
	watch: {
		tabModel(newValue) {
			this.$emit('input', newValue);
		}
	},
	mounted() {
		if (!this.isCreatingElement) {
			this.initialModel = Object.assign({}, this.model);
			const userCompanyType = this.$store.state.session.properties.COMPANY.companytypeid;

			if (userCompanyType !== this.pilotageCompanyType && userCompanyType !== this.APCompanyType && this.hasEditVisitFunctionality) {
				this.disableForm = false;
				this.checkVisitStatus();
			}
			this.getShipInfo();
			this.getVisitChat();
			this.getVisitStops();
			this.getVisitMMPP();
			this.getVisitSolas();
			this.getVisitIsps();
		} else {
			this.disableForm = false;
			this.model.portauthorityid = window.localStorage.getItem('workingPortAuthorityId');
			this.model.visityear = this.now.getFullYear();
			this.model.requestdate = this.now.toISOString();
		}
		this.checkShipList();
	},
	methods: {
		getShipInfo() {
			this.modelShip = this.model.shiptechnicaldata;
			this.modelShip.countrycode = this.model.shiptechnicaldata.country;
			this.modelShip.code = this.model.shiptechnicaldata.type;
			this.modelShip.manager = this.model.shiptechnicaldata.manager ? this.model.shiptechnicaldata.manager.code : null;
			this.modelShip.owner = this.model.shiptechnicaldata.owner ? this.model.shiptechnicaldata.owner.code : null;
			this.modelShip.callsign = this.model.shiptechnicaldata.callSign;
			this.modelShip.piclub = this.model.shiptechnicaldata.piClub;
			this.modelShip.maxspeed = this.model.shiptechnicaldata.maxSpeed;
			this.modelShip.sbtnumber = this.model.shiptechnicaldata.sbtNumber;
			this.modelShip.sbtvolume = this.model.shiptechnicaldata.sbtVolume;
			this.modelShip.sludgetank = this.model.shiptechnicaldata.sludgeTank;
			this.modelShip.wastewater = this.model.shiptechnicaldata.wasteWater;
			this.modelShip.dirtyballast = this.model.shiptechnicaldata.dirtyBallast;
			this.modelShip.oilwatertank = this.model.shiptechnicaldata.oilWaterTank;
			this.modelShip.builddatetime = this.model.shiptechnicaldata.buildDatetime;
			this.modelShip.cruisingspeed = this.model.shiptechnicaldata.cruisingSpeed;
			this.modelShip.riggingsheight = this.model.shiptechnicaldata.riggingsHeight;
			this.modelShip.propellernumber = this.model.shiptechnicaldata.propellerNumber;
			this.modelShip.deadweighttonnage = this.model.shiptechnicaldata.deadweightTonnage;
			this.modelShip.propellerlocation = this.model.shiptechnicaldata.propellerLocation;
			this.modelShip.classificationsociety = this.model.shiptechnicaldata.classificationSociety;
			this.modelShip.grossregisteredtonnage = this.model.shiptechnicaldata.grossRegisteredTonnage;
			this.modelShip.hullinsurance = this.model.shiptechnicaldata.hullInsurance;

			if (this.model.shiptechnicaldata.ramps) {
				for (let index = 0; index < this.model.shiptechnicaldata.ramps.length; index++) {
					const element = this.model.shiptechnicaldata.ramps[index];
					switch (index) {
						case 0:
							this.modelShip.ramp1 = element.location;
							this.modelShip.rampa1anchura = element.width;
							this.modelShip.rampa1alcance = element.length;

							break;
						case 1:
							this.modelShip.ramp2 = element.location;
							this.modelShip.rampa2anchura = element.width;
							this.modelShip.rampa2alcance = element.length;

							break;
						case 2:
							this.modelShip.ramp3 = element.location;
							this.modelShip.rampa3anchura = element.width;
							this.modelShip.rampa3alcance = element.length;

							break;
						case 3:
							this.modelShip.ramp4 = element.location;
							this.modelShip.rampa4anchura = element.width;
							this.modelShip.rampa4alcance = element.length;

							break;
						default:
							break;
					}
				}
			}
		},
		getVisitChat() {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data) {
					this.visitChat = response.data.data[0];
				}
			});
		},
		getVisitStops() {
			const opts = {
				model: 'stop',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data) {
					this.model.stops = response.data.data;
				}
			});
		},
		getVisitMMPP() {
			const opts = {
				model: 'visitmmpp',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.model.dangoods = response.data.data[0].dangoods;
					this.model.loaddangoods = response.data.data[0].loaddangoods;
					this.model.unloaddangoods = response.data.data[0].unloaddangoods;
					this.model.transitdangoods = response.data.data[0].transitdangoods;
					this.mmppVisit = true;
				}
			});
		},
		getVisitSolas() {
			const opts = {
				model: 'visitsolas',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.model.solascertcompany = response.data.data[0].solascertcompany;
					this.model.solascertexpiration = response.data.data[0].solascertexpiration;
					this.model.solascert = response.data.data[0].solascert;
					this.model.solastenports = response.data.data[0].solastenports;
					this.model.solassecurity = response.data.data[0].solassecurity;
					this.model.solaslevel = response.data.data[0].solaslevel;
					this.solasVisit = true;
				}
			});
		},
		getVisitIsps() {
			const opts = {
				model: 'visitisps',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.model.ispscompanyissue = response.data.data[0].ispscompanyissue;
					this.model.ispsexpiration = response.data.data[0].ispsexpiration;
					this.model.certisps = response.data.data[0].certisps;
					this.model.ispsreasonsnocert = response.data.data[0].ispsreasonsnocert;
					this.model.ispsnumber = response.data.data[0].ispsnumber;
					this.model.ispscomments = response.data.data[0].ispscomments;
					this.model.ispsplanprotect = response.data.data[0].ispsplanprotect;
					this.model.ispsactivitynotprocedure = response.data.data[0].ispsactivitynotprocedure;
					this.model.ispsalternativemeasures = response.data.data[0].ispsalternativemeasures;
					this.model.ispsstartnotprocedure = response.data.data[0].ispsstartnotprocedure;
					this.model.ispsendnotprocedure = response.data.data[0].ispsendnotprocedure;
					this.model.ispsprocedure = response.data.data[0].ispsprocedure;
					this.model.ispslastfacilities = response.data.data[0].ispslastfacilities;
					this.model.ispsaditionalmeasures = response.data.data[0].ispsaditionalmeasures;
					this.model.ispsportcode = response.data.data[0].ispsportcode;
					this.model.ispsisport = response.data.data[0].ispsisport;
					this.model.ispslocation = response.data.data[0].ispslocation;
					this.model.ispsprotectionlevel = response.data.data[0].ispsprotectionlevel;
					this.model.ispsocpmname = response.data.data[0].ispsocpmname;
					this.model.ispsocpmnumber = response.data.data[0].ispsocpmnumber;
					this.ispsVisit = true;
				}
			});
		},
		checkShipList() {
			const opts = {
				model: 'portauthority',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.$store.getters.getPortAuthorityId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.ownlistships = response.data.data[0].ownlistships;
				} else {
					this.ownlistships = false;
				}
			});
		},
		checkVisitStatus() {
			const opts = {
				model: 'visitstatuspa',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'portauthorityid',
							op: 'eq',
							data: this.$store.getters.getPortAuthorityId
						}
					]
				},
				order: [
					{
						column: 'orderby',
						direction: 'asc'
					}
				]
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.firstStatusByPA = response.data.data[0].visitstatuscode;
				}
			});
		}
	},
	destroyed() {},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.model.portid }];
		},
		itemsToSelectStatus() {
			return [{ visitstatuscode: this.model.status }];
		},
		itemsToSelectConsigneeCompany() {
			return [{ id: this.model.consigneeid }];
		},
		itemsToSelectShip() {
			return [{ id: this.model.shipid }];
		},
		itemsToSelectFromPortCode() {
			return [{ code: this.model.fromportcode }];
		},
		itemsToSelectToPortCode() {
			return [{ code: this.model.toportcode }];
		},
		itemsToSelectMaritimeService() {
			return [{ maritimeserviceid: this.model.maritimeserviceid }];
		},
		itemsToSelectMaritimeServiceExit() {
			return [{ maritimeserviceid: this.model.maritimeserviceexitid }];
		},
		itemsToSelectTrafficType() {
			return [{ traffictypecode: this.model.traffictypecode }];
		},
		itemsToSelectCountryArrival() {
			return [{ code: this.model.captainarrivalcountry }];
		},
		itemsToSelectCountryExit() {
			return [{ code: this.model.captainexitcountry }];
		},
		itemsToSelectCaptaincyStatus() {
			return [{ captaincystatusid: this.model.captaincystatus }];
		},
		editCommentsPilotage() {
			return this.$store.getters.getSession.functionalities.includes('EDIT_COMMENT_PILOTAGE');
		},
		hasEditVisitFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('EDIT_VISIT');
		},
		hasOperationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_OPERATION');
		},
		hasDoubleTideFunc() {
			return this.$store.getters.getSession.functionalities.includes('EDIT_DOUBLETIDE');
		},
		seeDoubleTideFunc() {
			return this.$store.getters.getSession.functionalities.includes('VIEW_DOUBLETIDE');
		},
		hasStopFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_STOP');
		},
		hasShipFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_SHIP');
		},
		hasMovementFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_MOVEMENT');
		},
		hasPilotageFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_PILOTAGE');
		},
		hasMooringFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_MOORING');
		},
		hasTowageFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_TOWAGE');
		},
		hasConsigChangeFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_CONSIGNMENTCHANGE');
		},
		hasLogAisEventsFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_LOGAISEVENTEXTENDED');
		},
		filterByConsigneeCompanyTypeAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'eq', data: this.model.portid },
					{ field: 'companytypeid', op: 'eq', data: this.consigneeCompanyType }
				]
			};
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'next_version_id', op: 'nu', data: null },
					this.ownlistships
						? { field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
						: { field: 'port_authority_id', op: 'nu', data: null }
				]
			};
		}
	}
};
</script>
